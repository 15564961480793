@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  background-color: #f6f6f6;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
